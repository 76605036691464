/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { camelCaseToHumanReadable } from "../../../helpers/utils";
import { ResearchLabDeviceType } from "../../../types/researchLabSchedule";

interface IDeviceTypeSelectionProps {
  label?: string;
  placeholder?: string;
  value?: ResearchLabDeviceType;
  onChange: (newVal?: ResearchLabDeviceType) => void;
  error?: boolean;
  helperText?: string;
}
export default function DeviceTypeSelection(props: IDeviceTypeSelectionProps) {
  const { value, onChange, helperText, error } = props;
  return (
    <TextField
      label={props.label || "Device Type"}
      select
      fullWidth
      placeholder="Select Device Type"
      helperText={helperText}
      error={error}
      value={value || ""}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as unknown as ResearchLabDeviceType);
      }}
    >
      {Object.values(ResearchLabDeviceType)
        .slice(Math.ceil(Object.values(ResearchLabDeviceType).length / 2))
        .map((role, i) => (
          <MenuItem
            key={`comp-type-${i}`}
            value={role as ResearchLabDeviceType}
          >
            {camelCaseToHumanReadable(
              Object.keys(ResearchLabDeviceType)[
                i + Math.ceil(Object.values(ResearchLabDeviceType).length / 2)
              ]
            )}
          </MenuItem>
        ))}
    </TextField>
  );
}
