/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
export enum AnalysisSliderSectionColor {
  ThinGreen = "#b0ecd8",
  DarkGreen = "#008000",
  Green = "#00b300",
  Red = "#de302e",
  Yellow = "#e7d128",
  White = "#fff",
}

export interface AnalysisSliderSection {
  fromValue?: number;
  toValue?: number;
  from: number;
  to: number;
  color: AnalysisSliderSectionColor;
  gradient?: boolean;
  gradientStartColor?: AnalysisSliderSectionColor;
}

export const AnalysisValueRanges: {
  [key: string]: { min: number; max: number };
} = {
  MBP: {
    min: 140,
    max: 225,
  },
  UNDF: {
    min: 12,
    max: 40,
  },
  Fast_Pool: {
    min: 14,
    max: 40,
  },
  Slow_Pool: {
    min: 1.5,
    max: 10,
  },
  CB1: {
    min: 12,
    max: 35,
  },
  FastHrMaxRateHours: {
    min: 1,
    max: 5,
  },
  SlowHrMaxRateHours: {
    min: 2,
    max: 14,
  },
  SlowFastDiff: {
    min: 0,
    max: 15,
  },
  APF: {
    min: 2,
    max: 5,
  },
};

export const AnalysisValueColorRanges: {
  [key: string]: AnalysisSliderSection[];
} = {
  MBP: [
    {
      from: 0,
      to: 23.5,
      color: AnalysisSliderSectionColor.Red,
    },
    {
      from: 23.5,
      to: 58.8,
      color: AnalysisSliderSectionColor.Green,
    },
    {
      from: 58.8,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
    },
  ],
  UNDF: [
    {
      from: 0,
      to: 33.333,
      color: AnalysisSliderSectionColor.Red,
    },
    {
      from: 33.333,
      to: 66.666,
      color: AnalysisSliderSectionColor.Yellow,
    },
    {
      from: 66.666,
      to: 100,
      color: AnalysisSliderSectionColor.Green,
    },
  ],
  Fast_Pool: [
    {
      from: 0,
      to: 19,
      color: AnalysisSliderSectionColor.Red,
    },
    {
      from: 19,
      to: 39,
      color: AnalysisSliderSectionColor.Green,
    },
    {
      from: 39,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
    },
  ],
  Slow_Pool: [
    {
      from: 0,
      to: 18,
      color: AnalysisSliderSectionColor.Red,
    },
    {
      from: 18,
      to: 36,
      color: AnalysisSliderSectionColor.Green,
    },
    {
      from: 36,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
    },
  ],
  CB1: [
    {
      from: 0,
      to: 21,
      color: AnalysisSliderSectionColor.Red,
    },
    {
      from: 21,
      to: 47,
      color: AnalysisSliderSectionColor.Green,
    },
    {
      from: 47,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
    },
  ],
  FastHrMaxRateHours: [
    {
      from: 0,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.ThinGreen,
    },
  ],
  SlowHrMaxRateHours: [
    {
      from: 0,
      to: 100,
      color: AnalysisSliderSectionColor.DarkGreen,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.ThinGreen,
    },
  ],
  SlowFastDiff: [
    {
      from: 0,
      to: 50,
      color: AnalysisSliderSectionColor.Green,
    },
    {
      from: 50,
      to: 100,
      color: AnalysisSliderSectionColor.Red,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.Green,
    },
  ],
  APF: [
    {
      from: 0,
      to: 30,
      color: AnalysisSliderSectionColor.Green,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.ThinGreen,
    },
    {
      from: 30,
      to: 36.333,
      color: AnalysisSliderSectionColor.DarkGreen,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.Green,
    },
    {
      from: 36.333,
      to: 63.666,
      color: AnalysisSliderSectionColor.DarkGreen,
    },
    {
      from: 63.666,
      to: 70,
      color: AnalysisSliderSectionColor.Green,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.DarkGreen,
    },
    {
      from: 70,
      to: 100,
      color: AnalysisSliderSectionColor.ThinGreen,
      gradient: true,
      gradientStartColor: AnalysisSliderSectionColor.Green,
    },
  ],
};

export const AnalysisValueUnit: { [key: string]: string } = {
  MBP: "mg/g",
  UNDF: "%",
  Fast_Pool: "Kd/Hr",
  Slow_Pool: "Kd/Hr",
};

export const getChemValueColor = (chemicalFieldName: string, value: number) => {
  if (!AnalysisValueColorRanges[chemicalFieldName] || !value) return "#333";
  const targetRange = AnalysisValueColorRanges[chemicalFieldName]?.find(
    (range) => {
      const fromValue =
        AnalysisValueRanges[chemicalFieldName].min +
        ((AnalysisValueRanges[chemicalFieldName].max -
          AnalysisValueRanges[chemicalFieldName].min) *
          range.from) /
          100;
      const toValue =
        AnalysisValueRanges[chemicalFieldName].min +
        ((AnalysisValueRanges[chemicalFieldName].max -
          AnalysisValueRanges[chemicalFieldName].min) *
          range.to) /
          100;
      return fromValue <= (value as any) && toValue >= (value as any);
    }
  );

  if (!targetRange) {
    return "#333";
  }

  const fromValue =
    AnalysisValueRanges[chemicalFieldName].min +
    ((AnalysisValueRanges[chemicalFieldName].max -
      AnalysisValueRanges[chemicalFieldName].min) *
      targetRange.from) /
      100;
  const toValue =
    AnalysisValueRanges[chemicalFieldName].min +
    ((AnalysisValueRanges[chemicalFieldName].max -
      AnalysisValueRanges[chemicalFieldName].min) *
      targetRange.to) /
      100;
  const density = 0.7 + (0.3 * (value - fromValue)) / (toValue - fromValue);
  const hexValue = Math.floor(density * 255)
    .toString(16)
    .padStart(2, "0");
  return `${targetRange.color}${hexValue}`;
};

export const HourlyMatrixValueRanges = {
  CH4: {
    min: 0,
    good: 15,
    max: 30,
  },
  C02: {
    min: 0,
    good: 500,
    max: 600,
  },
  Acetate: {
    min: 50,
    good: 60,
    max: 75,
  },
  Propionate: {
    min: 20,
    good: 30,
    max: 35,
  },
  TotalVFA: {
    min: 3,
    good: 8,
    max: 10,
  },
};
