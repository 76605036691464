/* eslint-disable @typescript-eslint/no-explicit-any */
import { SampleRecordFilterParams } from "../types/common";
import {
  ISampleRecordBaseFields,
  ISampleRecordCreationPayload,
  ISampleRecordPopulated,
} from "../types/sample";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/sample-record";

export interface ISampleRecordUpdatePayload {
  isChemical?: boolean;
  fieldName: string;
  oldValue: any;
  newValue: any;
}

interface GetDataParams extends SampleRecordFilterParams {
  page?: number;
  pageSize?: number;
  onlySpectra?: boolean;
  notIncludeSpectra?: boolean;
}
export default {
  async saveSampleRecords({
    companyId,
    clientId,
    newRecords,
    dataField,
    fileName,
    groupName,
    groupDescription,
    updateReason,
  }: {
    companyId: string;
    clientId?: string;
    newRecords: ISampleRecordCreationPayload[];
    dataField: string;
    fileName?: string;
    groupName?: string;
    groupDescription?: string;
    updateReason?: string;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/bulk-save`, {
        clientId,
        newRecords,
        companyId,
        dataField,
        fileName,
        groupName,
        groupDescription,
        updateReason,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async getSampleRecordsByIds(companyId: string, ids: string[]): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get-by-ids`, {
        companyId,
        ids,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async getData(params: GetDataParams): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get`, params);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async searchSamplesBySampleId(
    companyId: string,
    searchTerm: string,
    includeChemicals?: boolean
  ): Promise<ISampleRecordPopulated[]> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/search-sample-id`,
        { searchTerm, companyId, includeChemicals }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },
  async getFieldsData(
    filters: GetDataParams & { fields: string[] }
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-fields-data`,
        filters
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getLastSSIDs(companyId: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/get-last-company-uid`,
        { params: { companyId } }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async checkSampleExistence(
    companyId: string,
    sampleNumbers: string[],
    dataField?: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/check-sample-existence`,
        { sampleNumbers, companyId, dataField }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateRecord(
    uid: number,
    updateData: ISampleRecordBaseFields,
    updatedFields?: ISampleRecordUpdatePayload[],
    reason?: string
  ) {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-record`, {
        uid,
        updateData,
        updatedFields,
        reason,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateRecordProperty(uid: number, fieldName: string, newValue: any) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/update-record-property`,
        {
          uid,
          fieldName,
          newValue,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async bulkUpdateRecordProperty(
    uids: number[],
    fieldName: string,
    newValue: any
  ) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/bulk-update-record-property`,
        {
          uids,
          fieldName,
          newValue,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async delete(uids: number[]) {
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete`, {
        uids,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getPrediction(body: any) {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get-prediction`, {
        ...(body || {}),
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        throw error;
      }
    }
  },
  async getArchivedRecords(sampleNumber: string) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-archived-records`,
        {
          sampleNumber,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        throw error;
      }
    }
  },
  async getSpectraData(ids: string[]) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-spectra-data`,
        {
          ids,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        throw error;
      }
    }
  },
  async markAsReportMade(uids: number[]) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/mark-report-made`,
        { uids }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        throw error;
      }
    }
  },
  async getReportMadeSamples({
    companyId,
    fromDate,
    toDate,
    page,
    pageSize,
  }: {
    companyId: string;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    pageSize?: number;
  }) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-report-made-samples`,
        { companyId, fromDate, toDate, page, pageSize }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        throw error;
      }
    }
  },
};
