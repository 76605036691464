/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import Box from "@mui/material/Box";
import * as React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGridPro } from "@mui/x-data-grid-pro";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import { ISampleSource } from "../../../types/sampleSource";
import sampleSourceService from "../../../service/sampleSource.service";
import Button from "@mui/material/Button";
import SampleSourceDetailFormDialog from "./SampleSourceDetailFormDialog";

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [sampleSources, setSampleSources] = React.useState<ISampleSource[]>([]);
  const [sampleSourcesProcessing, setSampleSourcesProcessing] =
    React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openSampleSourceFormDialog, setOpenSampleSourceFormDialog] =
    React.useState(false);
  const [targetSampleSource, setTargetSampleSource] = React.useState<
    ISampleSource | undefined
  >(undefined);

  const columns = [
    {
      field: "name",
      headerName: "Lab Name",
      width: 150,
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 160,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 160,
    },
  ];

  const getFilteredSampleSources = () => {
    return sampleSources.filter(
      (c) =>
        !searchTerm ||
        c.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        c.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  };

  const fetchSampleSources = async () => {
    setSampleSourcesProcessing(true);
    const result = await sampleSourceService.fetchAllSampleSources();
    if (result) {
      setSampleSources(result);
    }
    setSampleSourcesProcessing(false);
  };

  React.useEffect(() => {
    fetchSampleSources();
  }, []);
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          Sample Sources (farms)
          <Box
            sx={{ ml: 1, fontSize: "24px", fontWeight: "500", opacity: "0.6" }}
          >{`(${sampleSources.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              placeholder="Search SampleSource"
            />
          </Box>

          {/* {selectedIds?.length ? (
            <Button
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null} */}
          <Box sx={{ flex: 1 }} />
          <Button
            onClick={() => {
              setTargetSampleSource(undefined);
              setOpenSampleSourceFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            Add New Farm
          </Button>
        </Box>
        <DataGridPro
          getRowId={(row) => row._id as string}
          loading={sampleSourcesProcessing}
          autoHeight
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(changedIds: any) => {
            setSelectedIds(changedIds);
          }}
          rows={getFilteredSampleSources()}
          columns={columns}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </WhitePanel>
      {openSampleSourceFormDialog && (
        <SampleSourceDetailFormDialog
          open={openSampleSourceFormDialog}
          onClose={() => setOpenSampleSourceFormDialog(false)}
          onSave={(savingSampleSource) => {
            const tempSampleSources = [...sampleSources];
            const targetIndex = tempSampleSources.findIndex(
              (sampleSource) => sampleSource._id === savingSampleSource._id
            );
            if (targetIndex > -1) {
              tempSampleSources.splice(
                tempSampleSources.findIndex(
                  (sampleSource) => sampleSource._id === savingSampleSource._id
                ),
                1,
                savingSampleSource
              );
            } else {
              tempSampleSources.unshift(savingSampleSource);
            }
            setSampleSources(tempSampleSources);
            setOpenSampleSourceFormDialog(false);
          }}
          initialSampleSource={targetSampleSource}
        />
      )}
    </>
  );
}
