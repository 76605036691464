/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { Layer } from "leaflet";
import {
  AnalysisValueRanges,
  AnalysisValueUnit,
  getChemValueColor,
} from "../Common/GasAnalysisReport/AnalysisConstants";
import { AnalysisValueColorRanges } from "../Common/GasAnalysisReport/AnalysisConstants";
import ValueColorSlider from "../Common/GasAnalysisReport/Sliders/ValueColorSlider";
import { Box } from "@mui/material";

export interface ChoroplethMapRegion {
  region: string;
  value: number;
  geojson?: any;
}

// Custom Info Control Component
const InfoControl: React.FC<{
  selectedRegion: ChoroplethMapRegion;
  errorMessage?: string;
  chemicalFieldName: string;
}> = ({ selectedRegion, errorMessage, chemicalFieldName }) => {
  const map = useMap();

  React.useEffect(() => {
    const infoControl = new L.Control({ position: "bottomleft" });

    infoControl.onAdd = () => {
      const div = L.DomUtil.create("div", "info");
      div.innerHTML = `<div style="min-width: 210px; display: flex; align-items: center; padding: 2px 8px; padding-top: 35px; background-color: white; border-radius: 5px; box-shadow: 0 0 15px rgba(0,0,0,0.2);">${
        selectedRegion
          ? `<div style="flex:1; margin-right: 8px;">${selectedRegion.region?.replaceAll(
              "::",
              " "
            )}</div>
            <b>${selectedRegion.value}</b> 
            ${
              selectedRegion.value && AnalysisValueUnit[chemicalFieldName]
                ? `<span style="font-size: 12px; margin-left: 4px;">${AnalysisValueUnit[chemicalFieldName]}</span>`
                : ""
            }
            ${
              errorMessage
                ? `<div style="color: red; font-size: 12px; font-weight: 600; margin-left: 8px;">${errorMessage}</div>`
                : ""
            }`
          : "Hover over a region"
      }</div>`;
      return div;
    };

    infoControl.addTo(map);

    return () => {
      infoControl.remove();
    };
  }, [map, selectedRegion]);

  return null;
};

const ChemicalChoroplethMap: React.FC<{
  regions: ChoroplethMapRegion[];
  chemicalFieldName: any;
}> = ({ regions, chemicalFieldName }) => {
  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  // Function to determine color based on region value
  const getColor = (value: number): string => {
    return getChemValueColor(chemicalFieldName, value);
  };

  // Define onEachFeature to add interactivity
  const onEachFeature = (feature: any, layer: Layer) => {
    layer.on({
      mouseover: (e: any) => {
        setSelectedRegion({
          region: feature.region,
          value: feature.value,
        });
        const layer = e.target;
        layer?.bringToFront && layer.bringToFront();
      },
      mouseout: (e: any) => {
        setSelectedRegion(null);
      },
    });
  };

  return (
    <Box
      sx={{
        position: "relative",
        "& .leaflet-bottom.leaflet-right": { display: "none" },
      }}
    >
      <MapContainer
        center={[47.2868352, -120.212613]} // Center on one of the regions
        zoom={1}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // attribution="&copy; OpenStreetMap contributors"
          attribution=""
        />
        {regions
          .filter((r) => r.geojson)
          .map((region, index) => (
            <GeoJSON
              key={index}
              data={{
                ...region.geojson,
                region: region.region,
                value: region.value,
              }}
              style={(feature: any) => {
                const value = region.value;
                return feature.geometry?.region == selectedRegion?.region
                  ? {
                      weight: 4,
                      color: "#537efd",
                      dashArray: "5",
                      fillOpacity: 0.65,
                    }
                  : {
                      fillColor: getColor(value),
                      weight: 2,
                      opacity: 1,
                      color: "white",
                      dashArray: "3",
                      fillOpacity: 0.65,
                    };
              }}
              onEachFeature={onEachFeature}
            />
          ))}
        <InfoControl
          chemicalFieldName={chemicalFieldName}
          selectedRegion={selectedRegion}
          errorMessage={
            AnalysisValueRanges[chemicalFieldName] &&
            selectedRegion?.value &&
            (AnalysisValueRanges[chemicalFieldName].min >
              selectedRegion.value ||
            AnalysisValueRanges[chemicalFieldName].max < selectedRegion.value
              ? "(Out of range)"
              : "")
          }
        />
      </MapContainer>
      {chemicalFieldName ? (
        <div
          style={{ position: "absolute", bottom: 28, left: 14, zIndex: 1000 }}
        >
          <ValueColorSlider
            sliderWidth={200}
            chemFieldName={chemicalFieldName}
            value={selectedRegion?.value}
          />
        </div>
      ) : null}
    </Box>
  );
};

export default ChemicalChoroplethMap;
