/* eslint-disable @typescript-eslint/no-explicit-any */

import backendApi from "../backendApi";
import { showSnackbar } from "../snackbar.service";

const routePrefix = "diet";

export default {
  async searchDiets(searchTerm: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/search`, {
        searchTerm,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getAllDiets(): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
