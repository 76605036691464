import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ISampleSource } from "../../../types/sampleSource";
import sampleSourceService from "../../../service/sampleSource.service";

interface SampleSourceDetailFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (sampleSource: ISampleSource) => void;
  initialSampleSource?: ISampleSource;
}

const SampleSourceDetailFormDialog: React.FC<
  SampleSourceDetailFormDialogProps
> = ({ open, onClose, onSave, initialSampleSource }) => {
  const [sampleSource, setSampleSource] = useState<Partial<ISampleSource>>(
    initialSampleSource || {
      name: "",
      address: "",
      email: "",
      phone: "",
    }
  );

  const handleSubmit = async () => {
    try {
      let result;
      if (initialSampleSource) {
        result = await sampleSourceService.updateSampleSource(
          initialSampleSource._id,
          sampleSource as ISampleSource
        );
      } else {
        result = await sampleSourceService.addNewSampleSource(
          sampleSource as ISampleSource
        );
      }

      onSave(result?._id ? result : (sampleSource as ISampleSource));
      onClose();
    } catch (error) {
      console.error("Error saving sample source:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        {initialSampleSource ? "Edit Sample Source" : "Add New Sample Source"}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="name"
          label="Name"
          fullWidth
          value={sampleSource.name}
          onChange={(event) =>
            setSampleSource({ ...sampleSource, name: event.target.value })
          }
          size="small"
        />
        <TextField
          margin="dense"
          name="address"
          label="Address"
          fullWidth
          value={sampleSource.address}
          onChange={(event) =>
            setSampleSource({ ...sampleSource, address: event.target.value })
          }
          size="small"
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          fullWidth
          value={sampleSource.email}
          onChange={(event) =>
            setSampleSource({ ...sampleSource, email: event.target.value })
          }
          size="small"
        />
        <TextField
          margin="dense"
          name="phone"
          label="Phone"
          fullWidth
          value={sampleSource.phone}
          onChange={(event) =>
            setSampleSource({ ...sampleSource, phone: event.target.value })
          }
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          {initialSampleSource ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SampleSourceDetailFormDialog;
