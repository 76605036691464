import { Box } from "@mui/material";
import { fDateTime } from "../../../helpers/formatTime";
import { fToNow } from "../../../helpers/formatTime";

export default function ToNowHoverTime({ time }: { time: string }) {
  return (
    <Box
      sx={{
        "&:hover .from-now": { display: "none !important" },
        "&:hover .exact-date": { display: "inline !important" },
      }}
    >
      <div className="from-now">{fToNow(time)}</div>
      <div className="exact-date" style={{ display: "none" }}>
        {fDateTime(time)}
      </div>
    </Box>
  );
}
