/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISampleSourceBaseFields } from "../types/sampleSource";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/sample-source";

export default {
  async fetchAllSampleSources(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/all`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewSampleSource(payload: ISampleSourceBaseFields): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        ...payload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New sample source successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async updateSampleSource(
    id: string,
    payload: ISampleSourceBaseFields
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        id,
        ...payload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New sample source successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
