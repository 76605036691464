import { UserRoles } from "./auth";
import { ICompany } from "./company";

export enum SpecialPermissions {
  Documentation = "Documentation",
  PredictionModel = "PredictionModel",
  RdSchedule = "RdSchedule",
}

export interface IPermissionStatus {
  permissionName: SpecialPermissions;
  read?: boolean;
  write?: boolean;
}

export interface IUserCreationPayload {
  companyId?: string;
  clientId?: string;
  fullname?: string;
  email?: string;
  role?: UserRoles;
  phone?: string;
  approved?: boolean;
  permissions?: IPermissionStatus[];
}

export interface IUserPublicProfile {
  _id?: string;
  company?: ICompany;
  fullname?: string;
  email?: string;
  role?: UserRoles;
  phone?: string;
  avatar?: string;
}

export interface IUser {
  company?: ICompany;
  fullname?: string;
  email?: string;
  password?: string;
  approved?: boolean;
  role?: UserRoles;
  phone?: string;
  avatar?: string;
  isConfirmed?: boolean;
  confirmOTP?: string;
  otpTries?: number;
  status?: boolean;
  _id?: string;
  permissions?: IPermissionStatus[];
}
