/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppContext } from "../../../contexts/useStorageContext";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";
import Chip from "@mui/material/Chip";
import { IDiet } from "../../../types/intake/diet";
import dietService from "../../../service/intakeServices/diet.service";

const InputThresholdBeforeSearch = 1;
export interface IDietAutocompleteBaseProps {
  label?: string;
  onSelectValue: (cow: IDiet) => void;
  value?: IDiet;
  error?: boolean;
  helperText?: any;
  companyId?: string;
  includeChemicals?: boolean;
  allDiets?: IDiet[];
}
type OmittedAutocompleteProps =
  | "value"
  | "onChange"
  | "renderInput"
  | "options";
const NewOptionPrefix = "New Sample ID: ";

export type IDietAutocompleteProps = IDietAutocompleteBaseProps &
  Omit<AutocompleteProps<IDiet, true, false, true>, OmittedAutocompleteProps>;

export default function DietAutocomplete({
  value,
  onSelectValue,
  fullWidth = false,
  label,
  companyId,
  includeChemicals,
  allDiets,
  ...otherProps
}: IDietAutocompleteProps) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<IDiet[]>(allDiets || []);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  async function search(searchText: string): Promise<void> {
    if (allDiets?.length) {
      setOptions(
        allDiets.filter(
          (d) =>
            !searchText ||
            d.name?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      return;
    }
    if (searchText.length < InputThresholdBeforeSearch) return;
    setSearching(true);
    try {
      const result = await dietService.searchDiets(searchText);
      if (result?.length) {
        setOptions(result);
      } else {
        setOptions([]);
      }
    } catch (error) {
      setSearching(false);
    }
    setSearching(false);
  }

  const debouncedSearch = debounce(search, 300);

  const handleSelectOption = (val: IDiet) => {
    onSelectValue(val);
  };

  React.useEffect(() => {
    if (allDiets?.length) {
      setOptions(allDiets);
    }
  }, [allDiets]);

  return (
    <>
      <Autocomplete<any, any, any, any>
        value={value}
        {...otherProps}
        options={options}
        clearOnBlur
        onChange={(e, v) => {
          handleSelectOption(v as IDiet);
        }}
        getOptionLabel={(option) => (option as IDiet)?.name || ""}
        renderOption={(props, option) => {
          return (
            <ListItem {...props} sx={{ p: 0, borderTop: "solid 1px #ddd" }}>
              <Box sx={{ flex: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>{option?.name || ""}</div>
                </div>
              </Box>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <TextField
              {...params}
              label={label}
              fullWidth
              placeholder={otherProps.placeholder}
              size="small"
              error={otherProps.error}
              helperText={otherProps.helperText}
              focused={value ? true : undefined}
            />
          </Box>
        )}
        onInputChange={(
          event: React.ChangeEvent<unknown>,
          searchText: string,
          reason: "input" | "reset" | "clear"
        ) => {
          if (
            searchText.length >= InputThresholdBeforeSearch &&
            reason === "input"
          ) {
            debouncedSearch(searchText);
            setSearchTerm(searchText);
          }
        }}
        filterOptions={(options, params) => {
          return options;
        }}
        popupIcon={
          searching ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            <ExpandMoreIcon />
          )
        }
      />
    </>
  );
}
