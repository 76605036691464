/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import * as XLSX from "xlsx";
import { Box, Button, ListItemIcon, MenuItem, Stack } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import UploadChemicalAnalysisDataDialog from "../../components/UploadChemicalAnalysisDataDialog";
import UploadSpectraDataDialog from "../../components/UploadSpectraDataDialog";
import {
  ChemicalDataBaseFields,
  ChemicalFields,
} from "../../../types/chemicals";
import UploadRawSpectraFileDialog from "../../components/UploadRawSpectraFile/UploadRawSpectraFileDialog";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";
import ContactSupportDialog from "../../components/ContactSupportDialog";

export default function ActionButtonGroup({
  lastChemSsid,
  lastSpecSsid,
}: {
  lastChemSsid?: number;
  lastSpecSsid?: number;
}) {
  const [openChemicalUploadDialog, setOpenChemicalUploadDialog] =
    React.useState(false);
  const [openSpectraUploadDialog, setOpenSpectraUploadDialog] =
    React.useState(false);
  const [openRawSpectraUploadDialog, setOpenRawSpectraUploadDialog] =
    React.useState(false);
  const [openContactSupportDialog, setOpenContactSupportDialog] =
    React.useState(false);
  const handleDownloadChemTemplate = React.useCallback(() => {
    const jsonData: any = {};
    ChemicalDataBaseFields.map((field) => {
      jsonData[field.fieldName] =
        field.dbField == "companyUid"
          ? lastChemSsid
            ? lastChemSsid + 1
            : 1
          : "";
    });

    ChemicalFields.map((field) => {
      jsonData[field.fieldName] = "";
    });
    const ws = XLSX.utils.json_to_sheet([jsonData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `chem_data.xlsx`);
  }, [lastChemSsid]);
  return (
    <Stack direction="row" spacing={1}>
      <Button
        sx={{ padding: { xs: "4px 8px" }, minHeight: "32px" }}
        onClick={() => handleDownloadChemTemplate()}
        variant="outlined"
        startIcon={<SaveAltIcon />}
      >
        <Box sx={{ display: { xs: "none", md: "block" }, mr: "4px" }}>
          Chem Template
        </Box>
      </Button>
      <GeneralPopoverWrapper
        triggerElement={
          <Button
            sx={{ padding: { xs: "4px 8px" }, minHeight: "32px" }}
            variant="outlined"
            startIcon={<FileUploadIcon />}
          >
            <Box sx={{ display: { xs: "none", md: "block" }, mr: "4px" }}>
              Upload
            </Box>
          </Button>
        }
        popoverContent={
          <Box py={1}>
            <MenuItem onClick={() => setOpenChemicalUploadDialog(true)}>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              Upload Chem Data (.xlsx, .csv)
            </MenuItem>
            <MenuItem onClick={() => setOpenSpectraUploadDialog(true)}>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              Upload Spectra Data (.xlsx, .csv)
            </MenuItem>
            <MenuItem onClick={() => setOpenRawSpectraUploadDialog(true)}>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              Upload Raw Spectra Data (.nir)
            </MenuItem>
          </Box>
        }
      />
      <Button
        onClick={() => setOpenContactSupportDialog(true)}
        variant="contained"
        disableElevation
        startIcon={<SupportAgentIcon />}
        sx={{ padding: { xs: "4px 12px" } }}
      >
        <Box sx={{ display: { xs: "none", md: "block" }, mr: "4px" }}>
          Contact Us
        </Box>
      </Button>
      {openChemicalUploadDialog && (
        <UploadChemicalAnalysisDataDialog
          onClose={() => setOpenChemicalUploadDialog(false)}
        />
      )}
      {openSpectraUploadDialog && (
        <UploadSpectraDataDialog
          onClose={() => setOpenSpectraUploadDialog(false)}
        />
      )}
      {openRawSpectraUploadDialog && (
        <UploadRawSpectraFileDialog
          onClose={() => setOpenRawSpectraUploadDialog(false)}
        />
      )}
      {openContactSupportDialog && (
        <ContactSupportDialog
          open={openContactSupportDialog}
          onClose={() => setOpenContactSupportDialog(false)}
        />
      )}
    </Stack>
  );
}
