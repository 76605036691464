/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  IResearchLabDevice,
  IResearchSchedule,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { MultiInputDateRangeField } from "@mui/x-date-pickers-pro/MultiInputDateRangeField";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import { cloneDeep } from "lodash";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DietAutocomplete from "../Intake/DietAutocomplete";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import ProgressButton from "../Common/ProgressButton";
import { twoTimePeriodsOverlap } from "../../../helpers/utils";
import animalService from "../../../service/intakeServices/animal.service";
import { ICow } from "../../../types/intake/cow";
import { fDashDate } from "../../../helpers/formatTime";
import dietService from "../../../service/intakeServices/diet.service";
import { IDiet } from "../../../types/intake/diet";
import SampleIdSingleAutocomplete from "../Common/SampleIdSingleutocomplete";

const getActualStartDate = (
  startDate: Date | undefined,
  habituationDays: number | undefined
) => {
  if (!habituationDays) return startDate;
  if (!startDate) return undefined;
  return dayjs(startDate).add(habituationDays, "day").toDate();
};

export default function ResearchScheduleForm({
  onChangeFormData,
  initialData,
  onNewCreated,
  onUpdated,
  otherFutureSchedules,
}: {
  onChangeFormData?: (formData: Partial<IResearchSchedule>) => void;
  initialData?: IResearchSchedule;
  onNewCreated?: (created: IResearchSchedule) => void;
  onUpdated?: (updated: IResearchSchedule) => void;
  otherFutureSchedules: IResearchSchedule[];
}) {
  const [formData, setFormData] = useState<
    Partial<IResearchSchedule> | undefined
  >(initialData);
  const [addingDevice, setAddingDevice] = useState<
    { type?: ResearchLabDeviceType; stock?: number } | undefined
  >(undefined);
  const [popoverKey, setPopoverKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<IResearchLabDevice[]>([]);
  const [animals, setAnimals] = useState<ICow[]>([]);
  const [fetchingAnimals, setFetchingAnimals] = useState(false);
  const [diets, setDiets] = useState<IDiet[]>([]);

  const getLabDevices = async () => {
    setIsLoading(true);
    const devices = await researchLabScheduleService.getLabDevices();
    setDevices(devices);
    setIsLoading(false);
  };

  const getDiets = async () => {
    const diets = await dietService.getAllDiets();
    setDiets(diets);
  };

  const fetchAnimals = async () => {
    setFetchingAnimals(true);
    const animals = await animalService.fetchAllCows();
    setAnimals(animals);
    setFetchingAnimals(false);
  };

  const handleCreateSchedule = async () => {
    if (!formData) return;
    setIsLoading(true);
    const result = await researchLabScheduleService.addNewSchedule(
      formData as any
    );
    onNewCreated && onNewCreated(result);
    setIsLoading(false);
  };

  const handleUpdateSchedule = async () => {
    if (!formData) return;
    setIsLoading(true);
    const result = await researchLabScheduleService.updateSchedule(
      formData as any
    );
    onUpdated && onUpdated(result);
    setIsLoading(false);
  };

  const availableCowIds = React.useMemo(() => {
    return animals
      .filter(
        (animal) =>
          !formData?.startDate ||
          !formData?.endDate ||
          !otherFutureSchedules?.filter(
            (s) =>
              twoTimePeriodsOverlap(
                formData?.startDate || new Date(),
                formData?.endDate || new Date(),
                s.startDate,
                s.endDate
              ) && s.participatedAnimals?.some((a) => a.cow?._id == animal._id)
          )?.length
      )
      .map((a) => a._id);
  }, [animals, formData?.startDate, formData?.endDate, otherFutureSchedules]);

  const availableDeviceIds = React.useMemo(() => {
    return devices
      .filter(
        (device) =>
          device.lastCalibratedDate &&
          dayjs().diff(dayjs(device.lastCalibratedDate), "day") <= 90 &&
          (!formData?.startDate ||
            !formData?.endDate ||
            !otherFutureSchedules?.filter(
              (s) =>
                twoTimePeriodsOverlap(
                  getActualStartDate(
                    formData?.startDate,
                    formData?.habituationDays
                  ) || new Date(),
                  formData?.endDate || new Date(),
                  getActualStartDate(s.startDate, s.habituationDays) ||
                    s.endDate,
                  s.endDate
                ) && s.participatedDevices?.some((d) => d._id == device._id)
            )?.length)
      )
      .map((a) => a._id);
  }, [devices, formData?.startDate, formData?.endDate, otherFutureSchedules]);

  React.useEffect(() => {
    if (onChangeFormData && formData) {
      onChangeFormData(formData as any);
    }
  }, [formData]);

  React.useEffect(() => {
    getLabDevices();
    fetchAnimals();
    getDiets();
  }, []);

  return (
    <div>
      <Stack spacing={3}>
        <Stack direction="row" spacing={2}>
          <TextField
            sx={{ minWidth: "250px" }}
            size="small"
            label="Name"
            value={formData?.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            size="small"
            fullWidth
            label="Description"
            value={formData?.description || ""}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </Stack>
        <Box
          sx={{
            "& .MuiMultiInputDateRangeField-separator": {
              marginLeft: "2px !important",
              marginRight: "2px !important",
            },
            "& .MuiFormControl-fullWidth": {
              marginLeft: "0px !important",
            },
          }}
        >
          <Stack direction="row" spacing={2}>
            <div>
              <GeneralPopoverWrapper
                triggerElement={
                  <Button
                    fullWidth
                    variant="outlined"
                    color={availableCowIds.length > 0 ? "primary" : "error"}
                    sx={{ whiteSpace: "nowrap", height: "40px" }}
                  >
                    Animals Available ({availableCowIds.length})
                  </Button>
                }
                popoverContent={
                  <Box p={2} sx={{ height: "350px", overflow: "auto" }}>
                    <DataGridPro
                      autoHeight
                      getRowId={(params) => params._id || ""}
                      rows={
                        animals?.filter((a) =>
                          availableCowIds.includes(a._id)
                        ) || []
                      }
                      columns={[
                        {
                          field: "name",
                          headerName: "Name",
                          width: 150,
                          valueGetter: (params) => params.row.name,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "breed",
                          headerName: "Breed",
                          width: 80,
                          valueGetter: (params) => params.row.breed?.name,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "scheduled",
                          headerName: "Scheduled",
                          width: 130,
                          renderCell: (params) => {
                            const scheduledTrials =
                              otherFutureSchedules?.filter((s) =>
                                s.participatedAnimals?.some(
                                  (a) => a.cow?._id == params.row._id
                                )
                              );
                            if (scheduledTrials?.length) {
                              return (
                                <GeneralPopoverWrapper
                                  triggerElement={
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label={`${scheduledTrials?.length} trial${
                                        scheduledTrials?.length > 1 ? "s" : ""
                                      } scheduled`}
                                      variant="outlined"
                                      size="small"
                                      color={
                                        !formData?.startDate ||
                                        !formData?.endDate
                                          ? "warning"
                                          : twoTimePeriodsOverlap(
                                              formData?.startDate || new Date(),
                                              formData?.endDate || new Date(),
                                              scheduledTrials[0].startDate,
                                              scheduledTrials[0].endDate
                                            )
                                          ? "error"
                                          : undefined
                                      }
                                    />
                                  }
                                  popoverContent={
                                    <Box
                                      p={2}
                                      sx={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <DataGridPro
                                        autoHeight
                                        getRowId={(params) => params._id || ""}
                                        rows={scheduledTrials || []}
                                        columns={[
                                          {
                                            field: "name",
                                            headerName: "Name",
                                            width: 150,
                                            sortable: false,
                                            editable: false,
                                          },
                                          {
                                            field: "period",
                                            headerName: "Period",
                                            width: 200,
                                            valueGetter: (params) =>
                                              `${fDashDate(
                                                params.row.startDate
                                              )} - ${fDashDate(
                                                params.row.endDate
                                              )}`,
                                            sortable: false,
                                            editable: false,
                                          },
                                        ]}
                                        columnHeaderHeight={32}
                                        rowHeight={32}
                                        disableColumnMenu
                                        hideFooter
                                        disableRowSelectionOnClick
                                      />
                                    </Box>
                                  }
                                />
                              );
                            } else {
                              return (
                                <div style={{ opacity: 0.6, fontSize: "14px" }}>
                                  No schedules
                                </div>
                              );
                            }
                          },
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "action",
                          headerName: "",
                          width: 50,
                          renderCell: (params) => (
                            <Checkbox
                              checked={formData?.participatedAnimals?.some(
                                (a) => a.cow?._id == params.row._id
                              )}
                              onChange={(e, checked) => {
                                const tempFormData = formData
                                  ? cloneDeep(formData)
                                  : {};
                                if (checked) {
                                  tempFormData.participatedAnimals = [
                                    ...(tempFormData.participatedAnimals || []),
                                    { cow: params.row },
                                  ];
                                } else {
                                  tempFormData.participatedAnimals =
                                    tempFormData.participatedAnimals?.filter(
                                      (a) => a.cow?._id != params.row._id
                                    );
                                }
                                setFormData(tempFormData);
                              }}
                            />
                          ),
                        },
                      ]}
                      columnHeaderHeight={32}
                      rowHeight={32}
                      disableColumnMenu
                      hideFooter
                      disableRowSelectionOnClick
                    />
                  </Box>
                }
              />
            </div>
            <div>
              <GeneralPopoverWrapper
                // anchorHorizontal="right"
                // transformHorizontal="right"
                triggerElement={
                  <Button
                    fullWidth
                    variant="outlined"
                    color={availableDeviceIds.length > 0 ? "primary" : "error"}
                    sx={{ whiteSpace: "nowrap", height: "40px" }}
                  >
                    Devices Available ({availableDeviceIds.length})
                  </Button>
                }
                popoverContent={
                  <Box p={2} sx={{ height: "350px", overflow: "auto" }}>
                    <DataGridPro
                      autoHeight
                      getRowId={(params) => params._id || ""}
                      rows={
                        devices?.filter((d) =>
                          availableDeviceIds.includes(d._id)
                        ) || []
                      }
                      columns={[
                        {
                          field: "type",
                          headerName: "Type",
                          width: 80,
                          valueGetter: (params) =>
                            Object.keys(ResearchLabDeviceType)[
                              Object.values(ResearchLabDeviceType).findIndex(
                                (v) => v == params.row.type
                              )
                            ],
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "deviceId",
                          headerName: "Device ID",
                          width: 150,
                          valueGetter: (params) => params.row.deviceId,
                          sortable: false,
                          editable: false,
                        },
                        {
                          field: "scheduled",
                          headerName: "Scheduled",
                          width: 130,
                          renderCell: (params) => {
                            const scheduledTrials =
                              otherFutureSchedules?.filter((s) =>
                                s.participatedDevices?.some(
                                  (d) => d._id == params.row._id
                                )
                              );
                            if (scheduledTrials?.length) {
                              return (
                                <GeneralPopoverWrapper
                                  triggerElement={
                                    <Chip
                                      sx={{ cursor: "pointer" }}
                                      label={`${scheduledTrials?.length} trial${
                                        scheduledTrials?.length > 1 ? "s" : ""
                                      } scheduled`}
                                      variant="outlined"
                                      size="small"
                                      color={
                                        !formData?.startDate ||
                                        !formData?.endDate
                                          ? "warning"
                                          : scheduledTrials?.some((ss) =>
                                              twoTimePeriodsOverlap(
                                                getActualStartDate(
                                                  formData?.startDate,
                                                  formData?.habituationDays
                                                ) || new Date(),
                                                formData?.endDate || new Date(),
                                                getActualStartDate(
                                                  ss.startDate,
                                                  ss.habituationDays
                                                ) || ss.endDate,
                                                ss.endDate
                                              )
                                            )
                                          ? "error"
                                          : undefined
                                      }
                                    />
                                  }
                                  popoverContent={
                                    <Box
                                      p={2}
                                      sx={{
                                        height: "200px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <DataGridPro
                                        autoHeight
                                        getRowId={(params) => params._id || ""}
                                        rows={scheduledTrials || []}
                                        columns={[
                                          {
                                            field: "name",
                                            headerName: "Name",
                                            width: 150,
                                            sortable: false,
                                            editable: false,
                                          },
                                          {
                                            field: "period",
                                            headerName: "Period",
                                            width: 200,
                                            valueGetter: (params) =>
                                              `${fDashDate(
                                                getActualStartDate(
                                                  params.row.startDate,
                                                  params.row.habituationDays
                                                ) || params.row.endDate
                                              )} - ${fDashDate(
                                                params.row.endDate
                                              )}`,
                                          },
                                        ]}
                                        columnHeaderHeight={32}
                                        rowHeight={32}
                                        disableColumnMenu
                                        hideFooter
                                        disableRowSelectionOnClick
                                      />
                                    </Box>
                                  }
                                />
                              );
                            } else {
                              return (
                                <div style={{ opacity: 0.6, fontSize: "14px" }}>
                                  No schedules
                                </div>
                              );
                            }
                          },
                        },
                        {
                          field: "action",
                          headerName: "",
                          width: 50,
                          renderCell: (params) => (
                            <Checkbox
                              checked={formData?.participatedDevices?.some(
                                (d) => d._id == params.row._id
                              )}
                              onChange={(e, checked) => {
                                const tempFormData = formData
                                  ? cloneDeep(formData)
                                  : {};
                                if (checked) {
                                  tempFormData.participatedDevices = [
                                    ...(tempFormData?.participatedDevices ||
                                      []),
                                    params.row,
                                  ];
                                } else {
                                  tempFormData.participatedDevices =
                                    tempFormData.participatedDevices?.filter(
                                      (d) => d._id != params.row._id
                                    );
                                }
                                setFormData(tempFormData);
                              }}
                            />
                          ),
                        },
                      ]}
                      columnHeaderHeight={32}
                      rowHeight={32}
                      disableColumnMenu
                      hideFooter
                      disableRowSelectionOnClick
                    />
                  </Box>
                }
              />
            </div>
            <TextField
              size="small"
              type="number"
              label="Habituation Days"
              value={formData?.habituationDays}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  habituationDays: e.target.value
                    ? Number(e.target.value)
                    : undefined,
                })
              }
              sx={{ width: "150px" }}
            />
            <div style={{ width: "300px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  formatDensity="dense"
                  disablePast
                  slots={{ field: MultiInputDateRangeField }}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  shouldDisableDate={(d, position) => {
                    const eachDate = d.toDate();
                    return (
                      formData?.participatedAnimals?.some((a) =>
                        otherFutureSchedules?.some(
                          (s) =>
                            s.participatedAnimals?.some(
                              (c) => c.cow?._id == a.cow?._id
                            ) &&
                            new Date(s.startDate) <= eachDate &&
                            new Date(s.endDate) >= eachDate
                        )
                      ) ||
                      formData?.participatedDevices?.some((d) =>
                        otherFutureSchedules?.some(
                          (s) =>
                            s.participatedDevices?.some(
                              (c) => c._id == d._id
                            ) &&
                            new Date(
                              getActualStartDate(
                                s.startDate,
                                s.habituationDays
                              ) || s.endDate
                            ) <= eachDate &&
                            new Date(s.endDate) >= eachDate
                        )
                      ) ||
                      false
                    );
                  }}
                  value={[
                    formData?.startDate ? dayjs(formData?.startDate) : null,
                    formData?.endDate ? dayjs(formData?.endDate) : null,
                  ]}
                  onChange={(val) => {
                    const tempFormData = formData ? cloneDeep(formData) : {};
                    if (val[0]) {
                      tempFormData.startDate = val[0]?.toDate();
                    }
                    if (val[1]) {
                      tempFormData.endDate = val[1]?.toDate();
                    }
                    setFormData(tempFormData);
                  }}
                />
              </LocalizationProvider>
            </div>
            {formData?.startDate && formData?.endDate && (
              <IconButton
                onClick={() => {
                  setFormData({
                    ...(formData || {}),
                    startDate: undefined,
                    endDate: undefined,
                  });
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                  opacity: 0.9,
                }}
              >
                Participated Animals (
                {formData?.participatedAnimals?.length || 0})
              </div>
              <Box
                sx={{
                  border: "solid 1px #ddd",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <DataGridPro
                  autoHeight
                  getRowId={(params) => params.cow._id || ""}
                  rows={formData?.participatedAnimals || []}
                  columns={[
                    {
                      field: "cow",
                      headerName: "Cow",
                      width: 150,
                      valueGetter: (params) => params.row.cow.name,
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "diet",
                      headerName: "Diet",
                      width: 200,
                      valueGetter: (params) => params.row.diet?.name || "N/A",
                      renderCell: (params: any) => (
                        <Box>
                          {params.row.diet ? (
                            <Chip
                              variant="outlined"
                              label={params.row.diet.name}
                              onDelete={() => {
                                const tempAnimals = cloneDeep(
                                  formData?.participatedAnimals || []
                                );
                                const animalIndex = tempAnimals?.findIndex(
                                  (a) => a.cow?._id == params.row.cow?._id
                                );
                                tempAnimals[animalIndex].diet = undefined;
                                setFormData({
                                  ...formData,
                                  participatedAnimals: tempAnimals,
                                });
                              }}
                            />
                          ) : (
                            <Box sx={{ minWidth: "250px" }}>
                              <DietAutocomplete
                                allDiets={diets}
                                size="small"
                                label=""
                                placeholder="Select Diet"
                                onSelectValue={(diet) => {
                                  const tempAnimals = cloneDeep(
                                    formData?.participatedAnimals || []
                                  );
                                  const animalIndex = tempAnimals?.findIndex(
                                    (a) => a.cow?._id == params.row.cow?._id
                                  );
                                  tempAnimals[animalIndex].diet = diet;
                                  setFormData({
                                    ...formData,
                                    participatedAnimals: tempAnimals,
                                  });
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      ),
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "actions",
                      headerName: "",
                      width: 40,
                      renderCell: (params) => (
                        <Box>
                          <Tooltip
                            title="Remove Animal"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => {
                                const tempAnimals = cloneDeep(
                                  formData?.participatedAnimals || []
                                );
                                const animalIndex = tempAnimals?.findIndex(
                                  (a) => a.cow?._id == params.row.cow?._id
                                );
                                tempAnimals.splice(animalIndex, 1);
                                setFormData({
                                  ...formData,
                                  participatedAnimals: tempAnimals,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    },
                  ]}
                  columnHeaderHeight={35}
                  rowHeight={35}
                  disableColumnMenu
                  hideFooter
                  disableRowSelectionOnClick
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "8px",
                  paddingLeft: "8px",
                  opacity: 0.9,
                }}
              >
                Participated Devices (
                {formData?.participatedDevices?.length || 0})
              </div>
              <Box
                sx={{
                  border: "solid 1px #ddd",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <DataGridPro
                  autoHeight
                  getRowId={(params) => params._id || ""}
                  rows={formData?.participatedDevices || []}
                  columns={[
                    {
                      field: "type",
                      headerName: "Type",
                      width: 80,
                      valueGetter: (params) =>
                        Object.keys(ResearchLabDeviceType)[
                          Object.values(ResearchLabDeviceType).findIndex(
                            (v) => v == params.row.type
                          )
                        ],
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "typeIndex",
                      headerName: "Type #",
                      width: 70,
                      valueGetter: (params) => params.row.typeIndex,
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "sample",
                      headerName: "Sample",
                      flex: 1,
                      renderCell: (params: any) => {
                        const matchedSample =
                          formData?.participatedSamples?.find(
                            (s) => s.deviceId == params.row._id
                          )?.sample;
                        return (
                          <Box>
                            {matchedSample ? (
                              <Chip
                                variant="outlined"
                                label={`${matchedSample.sampleNumber}`}
                                onDelete={() => {
                                  setFormData({
                                    ...formData,
                                    participatedSamples:
                                      formData?.participatedSamples?.filter(
                                        (s) =>
                                          s.sample?._id != matchedSample._id
                                      ),
                                  });
                                }}
                              />
                            ) : (
                              <Box sx={{ minWidth: "250px" }}>
                                <SampleIdSingleAutocomplete
                                  getErrorText={(s) =>
                                    formData?.participatedSamples?.some(
                                      (ps) => ps.sample?._id == s._id
                                    )
                                      ? "Already added to other device"
                                      : ""
                                  }
                                  placeholder="Find sample and attach"
                                  value={matchedSample}
                                  onSelectValue={(sample) => {
                                    setFormData({
                                      ...formData,
                                      participatedSamples: [
                                        ...(formData?.participatedSamples ||
                                          []),
                                        { sample, deviceId: params.row._id },
                                      ],
                                    });
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        );
                      },
                      sortable: false,
                      editable: false,
                    },
                    {
                      field: "actions",
                      headerName: "",
                      width: 40,
                      renderCell: (params) => (
                        <Box>
                          <Tooltip
                            title="Remove Device"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => {
                                const tempDevices = cloneDeep(
                                  formData?.participatedDevices || []
                                );
                                const deviceIndex = tempDevices?.findIndex(
                                  (d) => d._id == params.row._id
                                );
                                tempDevices.splice(deviceIndex, 1);
                                setFormData({
                                  ...formData,
                                  participatedDevices: tempDevices,
                                });
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    },
                  ]}
                  columnHeaderHeight={35}
                  rowHeight={35}
                  disableColumnMenu
                  hideFooter
                  disableRowSelectionOnClick
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              setFormData({});
            }}
            variant="outlined"
            size="small"
            color="error"
            sx={{ mr: 1 }}
          >
            Empty All Fields
          </Button>
          <ProgressButton
            variant="contained"
            size="small"
            disabled={!formData?.name || !formData?.startDate}
            onClick={
              initialData?._id ? handleUpdateSchedule : handleCreateSchedule
            }
            inProgress={isLoading}
            title={initialData?._id ? "Update" : "Create"}
          />
        </Box>
      </Stack>
    </div>
  );
}
