import { getAcetate, getPropionate } from "../helpers/gasAnalysisReport";
import { ISampleRecordPopulated } from "./sample";

export enum CropTypes {
  TMR = "TMR",
  CS = "CS",
  manure = "manure",
  Hay = "Hay",
  Haylage = "Haylage",
  FreshHayGrass = "Fresh Hay/Grass",
  CornGrain = "Corn Grain",
  Straw = "Straw",
  SmallGrainSilage = "Small Grain Silage",
  SoyCommodities = "Soy Commodities",
  AnimalProteinCommodities = "Animal Protein Commodities",
  GeneralCommodities = "General Commodities",
}

export const CropTypeCodeToName = {
  1: CropTypes.Hay,
  2: CropTypes.Haylage,
  3: CropTypes.CS,
  4: CropTypes.FreshHayGrass,
  6: CropTypes.TMR,
  7: CropTypes.CornGrain,
  9: CropTypes.Straw,
  22: CropTypes.SmallGrainSilage,
  50: CropTypes.manure,
  81: CropTypes.SoyCommodities,
  86: CropTypes.AnimalProteinCommodities,
  88: CropTypes.GeneralCommodities,
};

export const enabledCropTypes = [CropTypes.TMR];
interface IDataField {
  fieldName: string;
  aliases?: string[];
}
export interface ChemicalField extends IDataField {
  types: CropTypes[];
  requiredForGHG?: boolean;
  requiredForChemAnalysis?: boolean;
  requiredForGasReport?: boolean;
}

export const ChemicalFields: ChemicalField[] = [
  {
    types: [CropTypes.TMR],
    fieldName: "MBP",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "OMD",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "APF",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "NA",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "UNDF",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Fast_Pool",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Slow_Pool",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Fast_Pool_%",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Slow_Pool_%",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Fast_HR",
    aliases: ["fast hr"],
    requiredForGHG: true,
    requiredForGasReport: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Slow_HR",
    aliases: ["slow hr"],
    requiredForGHG: true,
    requiredForGasReport: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "asym1st",
    requiredForGHG: true,
    requiredForGasReport: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "asym2nd",
    requiredForGHG: true,
    requiredForGasReport: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "LagTime",
    aliases: ["lag time"],
    requiredForGHG: true,
    requiredForGasReport: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C:B1",
    aliases: ["CB1"],
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C:B3",
    aliases: ["CB3"],
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Hybrid",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Ml Gas_3",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Ml Gas_6",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Ml Gas_12",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Ml Gas_24",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.manure],
    fieldName: "CH4",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "CH4_3",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "CH4_6",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "CH4_12",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "CH4_24",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C02_3",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C02_6",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C02_12",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "C02_24",
  },
  {
    types: [CropTypes.TMR],
    fieldName: "H2_3",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "H2_6",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "H2_12",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "H2_24",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "ORP_3",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "ORP_6",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "ORP_12",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "ORP_24",
    requiredForGHG: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Acetate_3",
    aliases: ["Ace_3"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Acetate_6",
    aliases: ["Ace_6"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Acetate_12",
    aliases: ["Ace_12"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Acetate_24",
    aliases: ["Ace_24"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Propionate_3",
    aliases: ["Prop_3"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Propionate_6",
    aliases: ["Prop_6"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Propionate_12",
    aliases: ["Prop_12"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Propionate_24",
    aliases: ["Prop_24"],
  },
  {
    types: [CropTypes.TMR],
    fieldName: "DM",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "CP",
    aliases: ["Protein"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "NDICP",
    aliases: ["NDFICP"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "ADF",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "aNDF",
    aliases: ["ADF"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Lignin",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR, CropTypes.manure],
    fieldName: "Starch",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Sugar_WSC",
    aliases: ["WSC"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Fat_EE",
    aliases: ["Fat"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "Ash",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "NDF30",
    aliases: ["DNDFR30T"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "NDF120",
    aliases: ["DNDFR120"],
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.TMR],
    fieldName: "NSC",
    requiredForChemAnalysis: true,
  },
  {
    types: [CropTypes.manure],
    fieldName: "N20",
  },
];

export const findDbFieldNames = (randomFieldName: string) => {
  return ChemicalFields.filter(
    (f) =>
      f.fieldName?.toLowerCase() == randomFieldName?.toLowerCase() ||
      f.aliases?.some((a) => a.toLowerCase() == randomFieldName?.toLowerCase())
  )?.map((f) => f.fieldName);
};

export const autoFillChemValues = (chemData: { [key: string]: number }) => {
  const chemicals = { ...(chemData || {}) };
  // calculate NSC
  if (
    !chemicals?.["NSC"] &&
    chemicals?.["CP"] &&
    chemicals?.["aNDF"] &&
    chemicals?.["Ash"] &&
    chemicals?.["Fat_EE"]
  ) {
    chemicals["NSC"] = Number(
      (
        100 -
        (chemicals?.["CP"] +
          chemicals?.["aNDF"] +
          chemicals?.["Ash"] +
          chemicals?.["Fat_EE"])
      ).toFixed(2)
    );
  }
  // calculate Acetate
  if (!chemicals?.["Acetate_3"] && chemicals?.["ORP_3"]) {
    chemicals["Acetate_3"] = getAcetate(chemicals?.["ORP_3"]);
  }
  if (!chemicals?.["Acetate_6"] && chemicals?.["ORP_6"]) {
    chemicals["Acetate_6"] = getAcetate(chemicals?.["ORP_6"]);
  }
  if (!chemicals?.["Acetate_12"] && chemicals?.["ORP_12"]) {
    chemicals["Acetate_12"] = getAcetate(chemicals?.["ORP_12"]);
  }
  if (!chemicals?.["Acetate_24"] && chemicals?.["ORP_24"]) {
    chemicals["Acetate_24"] = getAcetate(chemicals?.["ORP_24"]);
  }

  // calculate Propionate
  if (!chemicals?.["Propionate_3"] && chemicals?.["ORP_3"]) {
    chemicals["Propionate_3"] = getPropionate(chemicals?.["ORP_3"]);
  }
  if (!chemicals?.["Propionate_6"] && chemicals?.["ORP_6"]) {
    chemicals["Propionate_6"] = getPropionate(chemicals?.["ORP_6"]);
  }
  if (!chemicals?.["Propionate_12"] && chemicals?.["ORP_12"]) {
    chemicals["Propionate_12"] = getPropionate(chemicals?.["ORP_12"]);
  }
  if (!chemicals?.["Propionate_24"] && chemicals?.["ORP_24"]) {
    chemicals["Propionate_24"] = getPropionate(chemicals?.["ORP_24"]);
  }
  return chemicals;
};

interface IChemicalDataBaseField extends IDataField {
  required?: boolean;
  dbField: keyof ISampleRecordPopulated;
}

export const ChemicalDataBaseFields: IChemicalDataBaseField[] = [
  {
    fieldName: "Lab SSID",
    dbField: "companyUid",
    aliases: ["SSID", "Lab ID"],
  },
  {
    fieldName: "Sample ID",
    required: true,
    dbField: "sampleNumber",
    aliases: [
      "Sample",
      "sample id",
      "sampleId",
      "sample number",
      "sample no.",
      "sample no",
    ],
  },
  {
    fieldName: "Client",
    required: false,
    dbField: "sampleClient",
    aliases: ["client", "sample client", "company", "dealer"],
  },
  {
    fieldName: "Farm",
    required: false,
    dbField: "sampleSource",
    aliases: ["farm", "sample source"],
  },
  {
    fieldName: "Data Source",
    required: false,
    dbField: "dataSource",
    aliases: ["data source"],
  },
  {
    fieldName: "Description",
    required: false,
    dbField: "description",
    aliases: ["desc"],
  },

  {
    fieldName: "Bag Weight",
    required: false,
    dbField: "bagWeight",
    aliases: ["weight", "bag weight", "bag"],
  },
  {
    fieldName: "Dataset",
    dbField: "dataset",
  },
  {
    fieldName: "Country",
    dbField: "country",
  },
  {
    fieldName: "Region",
    dbField: "region",
  },
  {
    fieldName: "Year",
    dbField: "year",
  },
  {
    fieldName: "Crop Type",
    required: true,
    dbField: "type",
    aliases: ["type"],
  },
  {
    fieldName: "Report Type",
    dbField: "reportType",
  },
  {
    fieldName: "Sample Taken Date",
    dbField: "sampleTakenDate",
    aliases: ["date", "Date", "sample date", "sampled date"],
  },
  {
    fieldName: "Product",
    dbField: "product",
    aliases: ["Product", "pc"],
  },
  {
    fieldName: "Application",
    dbField: "application",
    aliases: ["app"],
  },
];

export const NumberRoundDisabledDbFieldNames = ["Fast_HR", "Slow_HR"];

export interface IChemicalAnalysisItem {
  _id?: string;
  ssid: number;
  sampleNumber: string;
  sampleSource?: string;
  dataSource?: string;
  sampleClient?: string;
  bagWeight?: number;
  dataset?: string;
  country?: string;
  region?: string;
  year?: number;
  type?: CropTypes;
  reportType?: string;
  chemicals?: { [key: string]: number };
}
