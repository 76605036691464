/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IResearchLabDevice,
  IResearchSchedule,
  ResearchLabDeviceType,
} from "../../../types/researchLabSchedule";
import researchLabScheduleService from "../../../service/researchLabSchedule.service";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIconButton from "../Common/AddIconButton";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from "@mui/material";
import ResearchScheduleForm from "./ResearchScheduleForm";
import { fDashDate } from "../../../helpers/formatTime";
import Chip from "@mui/material/Chip";
import ResearchScheduleParticipatedAnimalsTable from "./ResearchScheduleParticipatedAnimalsTable";
import ConfirmDialog from "../Common/ConfirmDialog";
import { sortBy } from "lodash";
import { ICow } from "../../../types/intake/cow";
import { IDiet } from "../../../types/intake/diet";

export default function ResearchScheduleManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const [schedules, setSchedules] = useState<IResearchSchedule[]>([]);
  const [editingSchedule, setEditingSchedule] = useState<IResearchSchedule>();

  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const handleUpdateSchedule = async (
    id: string,
    updated: Partial<IResearchSchedule>
  ) => {
    if (!updated) return;
    setPopoverKey(popoverKey + 1);
    setIsLoading(true);
    await researchLabScheduleService.updateSchedule({ ...updated, _id: id });
    setIsLoading(false);
    getSchedules();
  };

  const columns = [
    { field: "name", headerName: "Name" },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueGetter: (params: any) =>
        params.value ? fDashDate(params.value) : "",
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueGetter: (params: any) =>
        params.value ? fDashDate(params.value) : "",
    },
    {
      field: "habituationDays",
      headerName: "Habituation",
      width: 100,
      renderCell: (params: any) => {
        return `${params.row.habituationDays || 0} days`;
      },
    },
    {
      field: "participatedDevices",
      headerName: "Devices",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <GeneralPopoverWrapper
            triggerElement={
              <Chip
                sx={{ cursor: "pointer" }}
                label={`${params.row.participatedDevices?.length || 0} devices`}
              />
            }
            popoverContent={
              <Box p={2}>
                <Table size="small" sx={{ border: "solid 1px #ddd" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Type #</TableCell>
                      <TableCell>Device ID</TableCell>
                      <TableCell>Sample Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {params.row.participatedDevices?.map(
                      (device: IResearchLabDevice) => (
                        <TableRow key={device._id}>
                          <TableCell>
                            {
                              Object.keys(ResearchLabDeviceType)[
                                Object.values(ResearchLabDeviceType).findIndex(
                                  (v) => v == device.type
                                )
                              ]
                            }
                          </TableCell>
                          <TableCell>{device.typeIndex}</TableCell>
                          <TableCell>{device.deviceId}</TableCell>
                          <TableCell>
                            {params.row.participatedSamples?.find(
                              (s: any) => s.deviceId == device._id
                            )?.sample?.sampleNumber || ""}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            }
          />
        );
      },
    },
    {
      field: "participatedAnimals",
      headerName: "Animals",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <GeneralPopoverWrapper
            triggerElement={
              <Chip
                sx={{ cursor: "pointer" }}
                label={`${params.row.participatedAnimals?.length || 0} cows`}
              />
            }
            popoverContent={
              <Box p={2}>
                <Table size="small" sx={{ border: "solid 1px #ddd" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cow</TableCell>
                      <TableCell>Breed</TableCell>
                      <TableCell>Diet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {params.row.participatedAnimals?.map(
                      (animal: { cow: ICow; diet: IDiet }) => (
                        <TableRow key={animal.cow._id}>
                          <TableCell>{animal.cow?.name}</TableCell>
                          <TableCell>{animal.cow?.breed?.name}</TableCell>
                          <TableCell>{animal.diet?.name}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            }
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={() => {
                setEditingSchedule(params.row);
                setOpenFormDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditingSchedule(params.row);
                setDeleteConfirmDialog(true);
              }}
              size="small"
              sx={{ ml: 1 }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const getSchedules = async () => {
    setIsLoading(true);
    const devices = await researchLabScheduleService.getSchedules();
    setSchedules(sortBy(devices, "startDate"));
    setIsLoading(false);
  };

  const handleDeleteSchedule = async (id: string) => {
    setIsLoading(true);
    await researchLabScheduleService.deleteSchedule(id);
    setIsLoading(false);
    getSchedules();
  };

  const handleAddSchedule = async () => {
    if (!editingSchedule) return;
    setPopoverKey(popoverKey + 1);
    setIsLoading(true);
    await researchLabScheduleService.addNewSchedule(editingSchedule);
    setEditingSchedule(undefined);
    setIsLoading(false);
    getSchedules();
  };

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <GeneralPopoverWrapper
          key={popoverKey}
          triggerElement={<AddIconButton onClick={() => {
            setEditingSchedule(undefined);
            setOpenFormDialog(true);
          }} />}
          popoverContent={
            <Box p={2} sx={{ minWidth: "250px" }}>
              <ResearchScheduleForm
                otherFutureSchedules={schedules.filter(
                  (s) =>
                    s._id != editingSchedule?._id &&
                    s.startDate &&
                    s.endDate &&
                    new Date(s.endDate) > new Date()
                )}
                onChangeFormData={(formData) =>
                  setEditingSchedule(formData as any)
                }
                initialData={editingSchedule}
                onNewCreated={() => {
                  getSchedules();
                  setPopoverKey(popoverKey + 1);
                }}
              />
            </Box>
          }
        /> */}
        <AddIconButton
          onClick={() => {
            setEditingSchedule(undefined);
            setOpenFormDialog(true);
          }}
        />
        <h2
          style={{
            margin: "0px 0px 0px 10px",
            display: "flex",
            alignItems: "center",
            opacity: 0.8,
          }}
        >
          R&D Trials
          <PendingActionsIcon sx={{ marginLeft: "8px" }} />
        </h2>
      </div>
      <div>
        <DataGridPro
          autoHeight
          rows={schedules}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </div>
      {openFormDialog && (
        <Dialog
          open={openFormDialog}
          onClose={() => setOpenFormDialog(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {editingSchedule?._id ? "Edit" : "Create"} R&D Trial
          </DialogTitle>
          <Box p={3}>
            <ResearchScheduleForm
              otherFutureSchedules={schedules.filter(
                (s) => s._id != editingSchedule?._id
              )}
              initialData={editingSchedule}
              onUpdated={(updated) => {
                getSchedules();
                setOpenFormDialog(false);
              }}
              onNewCreated={(created) => {
                getSchedules();
                setOpenFormDialog(false);
              }}
            />
          </Box>
        </Dialog>
      )}
      {deleteConfirmDialog && editingSchedule?._id && (
        <ConfirmDialog
          expanded
          title="Delete R&D Trial"
          content="Are you sure you want to delete this R&D Trial?"
          onConfirm={() => {
            handleDeleteSchedule(editingSchedule?._id || "");
            setDeleteConfirmDialog(false);
          }}
          onCancel={() => setDeleteConfirmDialog(false)}
        />
      )}
    </div>
  );
}
