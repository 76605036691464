import Box from "@mui/material/Box";
import {
  AnalysisSliderSection,
  AnalysisValueColorRanges,
  AnalysisValueRanges,
} from "../AnalysisConstants";

export default function ValueColorSlider({
  sliderWidth,
  chemFieldName,
  value,
}: {
  sliderWidth: number;
  chemFieldName: string;
  value?: number;
}) {
  if (!AnalysisValueRanges[chemFieldName]?.min) return null;
  const percentageValue = value
    ? ((value - AnalysisValueRanges[chemFieldName].min) * 100) /
      (AnalysisValueRanges[chemFieldName].max -
        AnalysisValueRanges[chemFieldName].min)
    : 0;
  const sections: AnalysisSliderSection[] = AnalysisValueColorRanges[
    chemFieldName
  ].map((range) => ({
    ...range,
    fromValue:
      AnalysisValueRanges[chemFieldName].min +
      (range.from *
        (AnalysisValueRanges[chemFieldName].max -
          AnalysisValueRanges[chemFieldName].min)) /
        100,
    toValue:
      AnalysisValueRanges[chemFieldName].min +
      (range.to *
        (AnalysisValueRanges[chemFieldName].max -
          AnalysisValueRanges[chemFieldName].min)) /
        100,
  }));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        pl: 1,
        pb: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            position: "relative",
            width: `${sliderWidth}px`,
            height: "8px",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              left: "0px",
              top: "0px",
              borderRadius: "30px",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
                borderRadius: "30px",
              }}
            >
              {sections.map((section, i) => (
                <div>
                  <Box
                    key={i}
                    sx={{
                      position: "absolute",
                      height: "100%",
                      width: `${section.to - section.from}%`,
                      left: `${section.from}%`,
                      backgroundColor: section.color,
                      backgroundImage: section.gradient
                        ? `linear-gradient(to right, ${
                            section.gradientStartColor || "white"
                          } , ${section.color})`
                        : undefined,
                    }}
                  />
                  {section.fromValue ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-18px",
                        fontSize: "11px",
                        left: `${section.from}%`,
                        transform: "translate(-50%, 0%)",
                        color: "#333",
                      }}
                    >
                      {Number(section.fromValue?.toFixed(1))}
                    </Box>
                  ) : null}
                  {i == sections.length - 1 ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-18px",
                        fontSize: "11px",
                        left: `${section.to}%`,
                        transform: "translate(-50%, 0%)",
                        color: "#333",
                      }}
                    >
                      {Number(section.toValue?.toFixed(1))}
                    </Box>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          {percentageValue ? (
            <Box
              sx={{
                display: percentageValue >= 0 ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                border: "solid 2px #666",
                borderRadius: "100px",
                left: `${percentageValue}%`,
                transform: "translate(-50%, -50%)",
                top: "50%",
                boxShadow:
                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "30px",
                  background: "#0000",
                  border: "solid 3px #fff",
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
