/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const CountryAliases = [
  {
    alias: "CDN",
    country: "Canada",
  },
  {
    alias: "CAN",
    country: "Canada",
  },
  {
    alias: "KSA",
    country: "Saudi Arabia",
  },
];

export const regionAliases = [
  {
    alias: "Wisonsin",
    region: "Wisconsin",
  },
];

export const getCoordinates = async (country: string, region: string) => {
  try {
    if (!country) return undefined;
    const exactCountryName =
      CountryAliases.find(
        (c) => c.alias?.toLowerCase() == country?.toLowerCase()
      )?.country || country;
    const exactRegionName =
      region == "undefined"
        ? ""
        : regionAliases.find(
            (r) => r.alias?.toLowerCase() == region?.toLowerCase()
          )?.region || region;
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?country=${exactCountryName}${
        exactRegionName ? `&state=${exactRegionName.toLowerCase() || ""}` : ""
      }&format=json`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      return { lat, lon };
    } else {
      console.error("No results found.");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};

export const getGoogleMapRegionId = async (country: string, region: string) => {
  try {
    if (!country) return undefined;
    const exactCountryName =
      CountryAliases.find(
        (c) => c.alias?.toLowerCase() === country?.toLowerCase()
      )?.country || country;
    const exactRegionName =
      region === "undefined"
        ? ""
        : regionAliases.find(
            (r) => r.alias?.toLowerCase() === region?.toLowerCase()
          )?.region || region;

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${exactCountryName},${exactRegionName}&key=YOUR_API_KEY`;
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      const regionId = data.results[0].place_id;
      return regionId;
    } else {
      console.error("No valid region found.");
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};

export const getRegionBoundaryCoordinates = async (
  country: string,
  region: string
): Promise<any> => {
  try {
    if (!country || !region) return undefined;
    const exactCountryName =
      CountryAliases.find(
        (c) => c.alias?.toLowerCase() === country?.toLowerCase()
      )?.country || country;
    const exactRegionName =
      region === "undefined"
        ? ""
        : regionAliases.find(
            (r) => r.alias?.toLowerCase() === region?.toLowerCase()
          )?.region || region;

    const url = `https://nominatim.openstreetmap.org/search?country=${exactCountryName}&state=${exactRegionName}&polygon_geojson=1&format=json`;
    const response = await fetch(url);
    const data = await response.json();
    if (data?.length > 0 && data?.[0]?.geojson) {
      return data[0].geojson;
    } else {
      console.error("No boundary data found.");
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching boundary coordinates:", error);
    throw error;
  }
};

export const getGoogleMapPlaceId = async (
  country: string,
  region: string
): Promise<string | undefined> => {
  try {
    if (!country || !region) return undefined;
    const exactCountryName =
      CountryAliases.find(
        (c) => c.alias?.toLowerCase() === country?.toLowerCase()
      )?.country || country;
    const exactRegionName =
      region === "undefined"
        ? ""
        : regionAliases.find(
            (r) => r.alias?.toLowerCase() === region?.toLowerCase()
          )?.region || region;

    const url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${exactCountryName},${exactRegionName}&inputtype=textquery&fields=place_id&key=YOUR_API_KEY`;
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK" && data.candidates.length > 0) {
      const placeId = data.candidates[0].place_id;
      return placeId;
    } else {
      console.error("No valid place ID found.");
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching Google Map Place ID:", error);
    throw error;
  }
};
