import { ICow } from "./intake/cow";
import { IDiet } from "./intake/diet";
import { ISampleRecordPopulated } from "./sample";

export enum ResearchLabDeviceType {
  G7 = 1,
  Redox = 2,
  PSI = 3,
}

export interface IResearchLabDevice {
  _id?: string;
  type: ResearchLabDeviceType;
  deviceId: string;
  lastCalibratedDate?: Date;
  typeIndex?: number;
  calibrationFiles?: string[];
}

export interface IResearchSchedule {
  _id?: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  participatedDevices?: IResearchLabDevice[];
  participatedAnimals?: {
    cow: ICow;
    diet?: IDiet;
  }[];
  participatedSamples: { sample: ISampleRecordPopulated; deviceId: string }[];
  habituationDays?: number;
}
