/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IResearchLabDevice,
  IResearchSchedule,
} from "../types/researchLabSchedule";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "research-lab-schedule";

export default {
  async getLabDevices(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/lab-devices`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addLabDevice(deviceData: IResearchLabDevice): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/add-lab-device`,
        deviceData
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New lab device successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateLabDevice(data: IResearchLabDevice): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/update-lab-device`,
        data
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Lab device successfully updated"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async deleteLabDevice(id: string): Promise<any> {
    try {
      const result = await backendApi()?.delete(
        `${routePrefix}/delete-lab-device/${id}`
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Lab device successfully deleted"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async addNewSchedule(scheduleData: IResearchSchedule): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/create`,
        scheduleData
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New schedule successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async updateSchedule(updateData: Partial<IResearchSchedule>): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/update`,
        updateData
      );
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Schedule successfully updated"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async getSchedules(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteSchedule(id: string): Promise<any> {
    try {
      const result = await backendApi()?.delete(`${routePrefix}/delete/${id}`);
      return result.data;
    } catch (error: any) {
      return null;
    }
  },
};
