/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeProvider } from "@mui/material";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "leaflet/dist/leaflet.css";

import "./App.css";
import Root from "./routes";
import { theme } from "./theme/muiThemes";
import React from "react";
import { AppWrapper } from "./contexts/useStorageContext";

function App() {
  return (
    <AppWrapper>
      <ReactNotifications />
      <ThemeProvider theme={theme}>
        <Root />
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;
