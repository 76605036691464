/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

interface ConfirmDialogProps {
  expanded?: boolean;
  title?: React.ReactElement | string;
  content?: React.ReactElement | string;
  onConfirmText?: React.ReactElement | string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonColor?: any;
}

function ConfirmDialog(props: ConfirmDialogProps): React.ReactElement {
  const {
    expanded,
    title,
    content,
    onConfirmText,
    onConfirm,
    onCancel,
    confirmButtonColor,
  } = { ...props };

  return (
    <Dialog open maxWidth={expanded ? "md" : "sm"} fullWidth onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      {content && (
        <>
          <Divider />
          <DialogContent>{content}</DialogContent>
          <Divider />
        </>
      )}
      <DialogActions style={{ marginBottom: 10, marginRight: 10 }}>
        <Button className="cancel-button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color={confirmButtonColor || "primary"}
          variant="contained"
          onClick={onConfirm}
        >
          {onConfirmText ? onConfirmText : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
