/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import * as React from "react";
import LoadDataFile from "../Common/LoadDataFile";
import {
  autoFillChemValues,
  ChemicalDataBaseFields,
  ChemicalField,
  ChemicalFields,
  findDbFieldNames,
  NumberRoundDisabledDbFieldNames,
} from "../../../types/chemicals";
import Button from "@mui/material/Button";
import ProgressButton from "../Common/ProgressButton";
import {
  ISampleRecordCreationPayload,
  ISampleRecordPopulated,
} from "../../../types/sample";
import CompanyAutoComplete from "../Common/CompanyAutoComplete";
import { useAppContext } from "../../../contexts/useStorageContext";
import { ICompany } from "../../../types/company";
import sampleRecordService from "../../../service/sampleRecord.service";
import { UserRoles } from "../../../types/auth";
import {
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import SampleSourceAutoComplete from "../Common/SampleSourceAutoComplete";
import { ISampleSource } from "../../../types/sampleSource";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import {
  GridRenderEditCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { serialNumberToDate } from "../../../helpers/formatTime";
import CreateNewSampleGroupDialog from "../SampleRecordsDataTable/CreateNewSampleGroupDialog";
import { getAcetate, getPropionate } from "../../../helpers/gasAnalysisReport";
import usePrevious from "../../../hooks/usePrevious";
import ReportTypeSelect, { ReportTypes } from "../Common/ReportTypeSelect";
import { cloneDeep, uniqBy } from "lodash";
import { getCoordinates } from "../../../service/geocode.service";

enum UpdateReasons {
  ImprovedAnalysis = "Improved Analysis",
  Other = "Other",
}

const getNumberValueColumn = (field: ChemicalField) => {
  return {
    field: field.fieldName,
    headerName: field.fieldName,
    width: 100,
    renderCell: (params: any) => {
      const row: ISampleRecordPopulated = params.row;
      return (
        <div>
          {row.chemicals?.[
            [field.fieldName, ...(field.aliases || [])].find(
              (fName) => row.chemicals?.[fName]
            ) || "none"
          ] || ""}
        </div>
      );
    },
  } as any;
};

// Component for uploading and managing chemical analysis data
export default function UploadChemicalAnalysisDataDialog({
  onClose,
  initialItems,
  initialFileName,
}: {
  onClose: () => void;
  initialItems?: ISampleRecordCreationPayload[];
  initialFileName?: string;
}) {
  const { state } = useAppContext(); // Accessing global state
  const { myCompanyProfile, loggedInUserInfo, myClients } = state; // Destructuring needed state properties
  const [errorMessage, setErrorMessage] = React.useState(""); // State for error messages
  const [analysisItems, setAnalysisItems] = React.useState<
    ISampleRecordCreationPayload[]
  >(initialItems?.map((s, i) => ({ ...s, temporaryId: i })) || []); // State for storing analysis items after parsing
  const [saving, setSaving] = React.useState(false); // State to indicate saving process
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const isAdmin =
    loggedInUserInfo?.role &&
    [UserRoles.superadmin, UserRoles.adminStaff].includes(
      loggedInUserInfo?.role as UserRoles
    );
  const [selectedCompany, setSelectedCompany] = React.useState<
    ICompany | undefined
  >(!isAdmin ? myCompanyProfile : undefined);
  const [selectedClient, setSelectedClient] = React.useState<
    ICompany | undefined
  >(
    !isAdmin && myCompanyProfile?.vendorCompanyId ? myCompanyProfile : undefined
  );
  const [existingDataset, setExistingDataset] = React.useState<
    { uid: number; companyUid: number; sampleNumber: string }[]
  >([]);
  const [checkingExistence, setCheckingExistence] = React.useState(false);
  const [selectedSampleSource, setSelectedSampleSource] =
    React.useState<ISampleSource>();
  const [fileName, setFileName] = React.useState(initialFileName || "");
  const [editingRow, setEditingRow] = React.useState<ISampleRecordPopulated>();
  const [openGroupCreationDialog, setOpenGroupCreationDialog] =
    React.useState(false);
  const prevStates = usePrevious({ analysisItems });
  const [reason, setReason] = React.useState<string>("");
  const [reasonText, setReasonText] = React.useState<string>("");
  const [validationError, setValidationError] = React.useState<string>("");
  const [initialCoordinatesFetched, setInitialCoordinatesFetched] =
    React.useState(false);
  const [fetchingCoordinates, setFetchingCoordinates] = React.useState(false);

  const getSingleCoordinates = async (country?: string, region?: string) => {
    if (!country) return undefined;
    return await getCoordinates(country, region || "");
  };

  const fillCoordinates = async () => {
    try {
      setFetchingCoordinates(true);
      const allUniqueRegions = uniqBy(
        analysisItems
          .filter((a) => a.country)
          .map((a) => `${a.country}__${a.region}`),
        (a) => a
      );
      const fetchCoordinates: Promise<
        { lat: number; lon: number } | undefined
      >[] = allUniqueRegions.map((reg) =>
        getCoordinates(reg.split("__")[0], reg.split("__")[1])
      );
      const coordinates = await Promise.all(fetchCoordinates);
      const tempAnalysisItems = cloneDeep(analysisItems);
      allUniqueRegions.map((reg, index) => {
        const coor = coordinates[index];
        if (
          coor &&
          tempAnalysisItems.find((a) => `${a.country}__${a.region}` == reg)
        ) {
          tempAnalysisItems.map((item) => {
            if (`${item.country}__${item.region}` == reg) {
              item.coordinates = coor;
            }
          });
        }
      });
      setAnalysisItems(tempAnalysisItems);
      setInitialCoordinatesFetched(true);
      setFetchingCoordinates(false);
    } catch (error) {
      setFetchingCoordinates(false);
    }
  };

  const handleUpdateRow = (
    temporaryId: number,
    updatedRow: ISampleRecordPopulated
  ) => {
    const tempAnalysisItems = [...analysisItems];
    const targetIndex = tempAnalysisItems.findIndex(
      (a) => a.temporaryId == temporaryId
    );
    if (targetIndex < 0) return;
    tempAnalysisItems.splice(targetIndex, 1, updatedRow);
    setAnalysisItems(tempAnalysisItems);
    return updatedRow;
  };

  const TextCellEditElement = (
    params: GridRenderEditCellParams<
      ISampleRecordPopulated,
      any,
      any,
      GridTreeNodeWithRender
    >
  ) => {
    return (
      <TextField
        size="small"
        value={editingRow?.[params.field as keyof ISampleRecordPopulated]}
        onChange={(e) => {
          if (editingRow)
            setEditingRow({
              ...editingRow,
              [params.field as keyof ISampleRecordPopulated]: e.target.value,
            });
        }}
        sx={{
          "& input": {
            py: "6.7px !important",
            px: "4px",
          },
        }}
        inputProps={{
          tabIndex: columns.findIndex(
            (column) => column.field === params.field
          ),
        }}
      />
    );
  };

  const columns = [
    {
      field: "uid",
      headerName: "",
      width: 130,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {existingDataset?.some(
              (d) => d.sampleNumber == row.sampleNumber
            ) ? (
              <Tooltip
                title="Sample ID already existing. Will be updated by this."
                placement="right"
                arrow
              >
                <ErrorIcon color="warning" sx={{ mr: 1 }} />
              </Tooltip>
            ) : !!selectedCompany && !checkingExistence ? (
              <Chip
                size="small"
                label="NEW"
                variant="outlined"
                color="success"
              />
            ) : null}

            {existingDataset?.find((d) => d.sampleNumber == row.sampleNumber)
              ?.uid || ""}
          </div>
        );
      },
    },
    {
      field: "companyUid",
      headerName: "Lab SSID",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordCreationPayload = params.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.companyUid ||
              existingDataset?.find((d) => d.sampleNumber == row.sampleNumber)
                ?.companyUid}
          </div>
        );
      },
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "sampleNumber",
      headerName: "Sample #",
      width: 150,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "sampleSource",
      headerName: "Farm",
      width: 150,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "dataSource",
      headerName: "Data Source",
      width: 150,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "bagWeight",
      headerName: "Bag Weight (gram)",
      width: 120,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "predictionAiModel",
      headerName: "Prediction Model",
      width: 150,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.predictionAiModel?.title || row.predictionAiModel?.date;
      },
    },
    {
      field: "dataset",
      headerName: "Dataset",
      width: 70,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "country",
      headerName: "Country",
      width: 80,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "region",
      headerName: "Region",
      width: 100,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "year",
      headerName: "Year",
      width: 65,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "type",
      headerName: "Type",
      width: 80,
      editable: true,
      sortable: false,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "reportType",
      headerName: "Report Type",
      width: 130,
      editable: true,
      renderEditCell: (params: any) => {
        return (
          <ReportTypeSelect
            value={editingRow?.reportType as ReportTypes}
            onChange={(newVal) => {
              if (editingRow) {
                setEditingRow({ ...editingRow, reportType: newVal });
              }
            }}
          />
        );
      },
    },
    {
      field: "sampleTakenDate",
      headerName: "Sampled Date",
      width: 100,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "product",
      headerName: "Product",
      width: 100,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    {
      field: "application",
      headerName: "Application",
      width: 100,
      editable: true,
      renderEditCell: TextCellEditElement,
    },
    ...ChemicalFields.map((field) => getNumberValueColumn(field)),
  ];

  const onParseData = (data: any, fileName?: string) => {
    setFileName(fileName || "");
    const headerFields: string[] = data?.[0]?.map((f: string) => f.trim());
    if (!headerFields?.length) {
      setErrorMessage("Header row is empty");
      return;
    }

    // check required fields are existing
    const missingRequiredFields: string[] = [];
    ChemicalDataBaseFields.map((field) => {
      if (
        field.required &&
        ![field.fieldName, ...(field.aliases || [])].some((f) =>
          headerFields.map((hf) => hf.toLowerCase()).includes(f.toLowerCase())
        )
      ) {
        missingRequiredFields.push(field.fieldName);
      }
    });
    if (missingRequiredFields?.length) {
      setErrorMessage(
        `Required fields are missing: ${missingRequiredFields.join(", ")}`
      );
      return;
    }

    const parsedChemicalItems: ISampleRecordCreationPayload[] = [];
    const baseFieldsIndexMapper: any = {};
    ChemicalDataBaseFields.map((f) => {
      baseFieldsIndexMapper[f.dbField] = headerFields.findIndex(
        (hf) =>
          hf &&
          [f.fieldName, ...(f.aliases || [])].some(
            (a) => a.toLowerCase() == hf.toLowerCase()
          )
      );
    });

    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      const chemicalItem: ISampleRecordCreationPayload = {
        temporaryId: i,
        companyUid: row[baseFieldsIndexMapper["companyUid"]],
        sampleNumber: row[baseFieldsIndexMapper["sampleNumber"]]
          ?.toString()
          ?.trim(),
        sampleClient: row[baseFieldsIndexMapper["sampleClient"]]
          ?.toString()
          ?.trim(),
        sampleSource: row[baseFieldsIndexMapper["sampleSource"]]
          ?.toString()
          ?.trim(),
        dataSource: row[baseFieldsIndexMapper["dataSource"]]
          ?.toString()
          ?.trim(),
        bagWeight: row[baseFieldsIndexMapper["bagWeight"]],
        dataset: row[baseFieldsIndexMapper["dataset"]],
        reportType: row[baseFieldsIndexMapper["reportType"]],
        country: row[baseFieldsIndexMapper["country"]],
        region: row[baseFieldsIndexMapper["region"]],
        year: row[baseFieldsIndexMapper["year"]],
        type: row[baseFieldsIndexMapper["type"]],
        description: row[baseFieldsIndexMapper["description"]],
        sampleTakenDate: row[baseFieldsIndexMapper["sampleTakenDate"]]
          ? serialNumberToDate(row[baseFieldsIndexMapper["sampleTakenDate"]])
          : undefined,
        product: row[baseFieldsIndexMapper["product"]],
        application: row[baseFieldsIndexMapper["application"]],
      };
      const chemicals: { [key: string]: number } = {};
      row.map((val: number, j: number) => {
        if (!Object.values(baseFieldsIndexMapper).includes(j)) {
          const dbFieldNames = findDbFieldNames(headerFields[j]);
          if (dbFieldNames?.length) {
            if (!isNaN(val)) {
              dbFieldNames.map((dbFieldName) => {
                chemicals[dbFieldName] =
                  NumberRoundDisabledDbFieldNames.includes(dbFieldName)
                    ? Number(val)
                    : Number(Number(val).toFixed(2));
              });
            }
          }
        }
      });
      chemicalItem.chemicals = autoFillChemValues(chemicals);
      parsedChemicalItems.push(chemicalItem);
    }
    setAnalysisItems(parsedChemicalItems);
  };

  const saveData = async (groupName: string, groupDescription: string) => {
    if (!selectedCompany?._id) return;
    setSaving(true);
    const result = await sampleRecordService.saveSampleRecords({
      clientId: selectedClient?._id,
      companyId: selectedCompany._id,
      newRecords: analysisItems,
      dataField:
        analysisItems?.[0]?.spectraItems?.length &&
        Object.keys(analysisItems?.[0]?.chemicals || {})?.length
          ? "raw"
          : "chemicals",
      fileName,
      groupName,
      groupDescription,
      updateReason: reason == UpdateReasons.Other ? reasonText : reason,
    });
    if (result?.status == 200) {
      setSaveSuccess(true);
    }
    setSaving(false);
  };

  const checkSampleExistence = async () => {
    if (!selectedCompany?._id || !analysisItems?.length) return;
    setCheckingExistence(true);
    const result = await sampleRecordService.checkSampleExistence(
      selectedClient?._id || selectedCompany._id,
      analysisItems.map((a) => a.sampleNumber || ""),
      "chemicals"
    );
    setExistingDataset(result.existingSamples || []);
    let newCompanyUid = result.maxCompanyUid;
    const companyUidAssignedItems = analysisItems.map((item) => {
      if (
        result.existingSamples?.some(
          (s: any) => s.sampleNumber == item.sampleNumber
        )
      ) {
        return item;
      }
      newCompanyUid = newCompanyUid + 1;
      return {
        ...item,
        companyUid: newCompanyUid,
      };
    });
    setAnalysisItems(companyUidAssignedItems);
    setCheckingExistence(false);
  };

  React.useEffect(() => {
    setValidationError("");
  }, [reason, reasonText]);

  React.useEffect(() => {
    if (selectedCompany?._id && analysisItems?.length) {
      checkSampleExistence();
    }
  }, [selectedCompany, selectedClient]);
  React.useEffect(() => {
    if (
      selectedCompany?._id &&
      analysisItems?.length &&
      prevStates?.analysisItems
        ?.map((s) => s.sampleNumber)
        ?.sort()
        ?.join(",") !=
        analysisItems
          ?.map((s) => s.sampleNumber)
          ?.sort()
          ?.join(",")
    ) {
      checkSampleExistence();
    }
  }, [analysisItems]);

  React.useEffect(() => {
    if (analysisItems.length && selectedSampleSource) {
      setAnalysisItems(
        analysisItems.map((a) => ({
          ...a,
          sampleSource: selectedSampleSource.name,
        }))
      );
    }
  }, [selectedSampleSource]);

  // React.useEffect(() => {
  //   if (!initialCoordinatesFetched && analysisItems?.length) {
  //     fillCoordinates();
  //   }
  // }, [initialCoordinatesFetched, analysisItems]);

  return (
    <Dialog open maxWidth={false} onClose={onClose}>
      <div style={{ background: "white", maxWidth: "100%" }}>
        <Box p={2}>
          <Box pb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Upload Chem Data</Typography>
            <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            {errorMessage ? (
              <Box
                sx={{
                  color: "error.main",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  p: 2,
                }}
              >
                {errorMessage}
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => setErrorMessage("")}
                >
                  Try other file
                </Button>
              </Box>
            ) : analysisItems.length ? (
              <Box pb={1}>
                <Box
                  sx={{
                    height: "500px",
                    margin: "auto",
                  }}
                >
                  <DataGridPro
                    onRowDoubleClick={(params) => {
                      if (editingRow?.uid != params.id) {
                        setEditingRow(params.row);
                      }
                    }}
                    processRowUpdate={async (newRow, oldRow) => {
                      if (editingRow) {
                        const updatedRow = cloneDeep(editingRow);
                        // if (
                        //   `${editingRow.country}__${editingRow.region}` !=
                        //   `${oldRow.country}__${oldRow.region}`
                        // ) {
                        //   const changedCoordinates = await getSingleCoordinates(
                        //     editingRow.country,
                        //     editingRow.region
                        //   );
                        //   updatedRow.coordinates = changedCoordinates;
                        // }
                        const updated = await handleUpdateRow(
                          oldRow.temporaryId || -1,
                          updatedRow
                        );
                        setEditingRow(undefined);
                        return updated || newRow;
                      } else {
                        setEditingRow(undefined);
                        return newRow;
                      }
                    }}
                    editMode="row"
                    getRowId={(row) => row.temporaryId || row.companyUid || 0}
                    columns={columns}
                    rows={analysisItems}
                    rowHeight={38}
                    disableRowSelectionOnClick
                    initialState={{
                      pinnedColumns: {
                        left: ["uid", "companyUid", "sampleNumber", "type"],
                      },
                    }}
                  />
                </Box>

                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  {checkingExistence ||
                  !selectedCompany?._id ||
                  !analysisItems?.length ? (
                    <div style={{ flex: 1 }} />
                  ) : (
                    <div style={{ flex: 1, marginRight: "8px" }}>
                      <div style={{ maxWidth: "470px" }}>
                        {existingDataset?.length ? (
                          <Box sx={{ mt: "-42px" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  color: "warning.main",
                                  background: "#ffe3c0",
                                  padding: "10px 9px",
                                  borderRadius: "5px",
                                  mb: 1,
                                }}
                              >{`${existingDataset?.length} samples are already existing and will be updated.`}</Box>
                              {fetchingCoordinates ? (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <CircularProgress size={16} sx={{ mr: 1 }} />
                                  Fetching coordinates...
                                </Box>
                              ) : null}
                            </Box>
                            <FormControl>
                              <RadioGroup
                                row
                                name="reason"
                                value={reason}
                                onChange={(e, value) =>
                                  setReason(value as UpdateReasons)
                                }
                              >
                                {Object.values(UpdateReasons).map((reason) => (
                                  <FormControlLabel
                                    value={reason}
                                    control={<Radio />}
                                    label={reason}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                            {reason == UpdateReasons.Other ? (
                              <TextField
                                sx={{ mt: 1 }}
                                placeholder="Reason for update"
                                value={reasonText}
                                onChange={(e) => setReasonText(e.target.value)}
                                size="small"
                                fullWidth
                              />
                            ) : null}
                            {validationError ? (
                              <Box
                                sx={{
                                  color: "error.main",
                                  mt: 1,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <WarningIcon sx={{ mr: 1 }} color="error" />
                                {validationError}
                              </Box>
                            ) : null}
                          </Box>
                        ) : null}
                        {!existingDataset?.length ? (
                          <Box
                            sx={{
                              color: "success.main",
                              background: "#ceffe5",
                              padding: "10px 9px",
                              borderRadius: "5px",
                            }}
                          >
                            All samples are new records
                          </Box>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <Stack
                    style={{
                      marginRight: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    spacing={1}
                    direction="row"
                  >
                    {!isAdmin ? null : (
                      <CompanyAutoComplete
                        label={"From Which Lab"}
                        onlyDirectPartners
                        value={selectedCompany}
                        onChange={(selected) =>
                          setSelectedCompany(selected || undefined)
                        }
                      />
                    )}
                    {(isAdmin || myClients?.length) && selectedCompany ? (
                      <CompanyAutoComplete
                        enabled
                        limitedOptions={isAdmin ? undefined : myClients}
                        definedVendorId={selectedCompany?._id}
                        label={"From Which Client"}
                        value={selectedClient}
                        onChange={(selected) =>
                          setSelectedClient(selected || undefined)
                        }
                      />
                    ) : null}
                  </Stack>
                  {/* <div style={{ marginRight: "8px", minWidth: "250px" }}>
                    <SampleSourceAutoComplete
                      fullWidth
                      label="Sample Source (Farm)"
                      value={selectedSampleSource}
                      onChange={(selected) =>
                        setSelectedSampleSource(selected || undefined)
                      }
                    />
                  </div> */}

                  {saveSuccess ? (
                    <Box
                      sx={{
                        color: "success.main",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon sx={{ mr: 1 }} />
                      Saved Successfully
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ProgressButton
                        onClick={() => {
                          if (
                            existingDataset?.length &&
                            (!reason ||
                              (reason == UpdateReasons.Other && !reasonText))
                          ) {
                            setValidationError("Reason for update is required");
                          } else {
                            setOpenGroupCreationDialog(true);
                          }
                        }}
                        inProgress={saving}
                        title="Save Data"
                        variant="contained"
                        disableElevation={false}
                        disabled={!selectedCompany || checkingExistence}
                      />
                    </Box>
                  )}
                </div>
              </Box>
            ) : (
              <LoadDataFile onParseData={onParseData} />
            )}
          </Box>
        </Box>
      </div>
      {openGroupCreationDialog && (
        <CreateNewSampleGroupDialog
          dialogTitle="Save As A Group"
          recordCount={analysisItems?.length || 0}
          onComplete={(groupName, description) =>
            saveData(groupName, description)
          }
          onClose={() => {
            setOpenGroupCreationDialog(false);
          }}
          cancelButtonTitle="Save without grouping"
        />
      )}
    </Dialog>
  );
}
