/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box, Grid } from "@mui/material";
import WhitePanel from "../../components/Common/WhitePanel";
import LabDeviceManagement from "../../components/LabDevices/LabDeviceManagement";
import ResearchScheduleManagement from "../../components/LabResearchSchedule/ResearchScheduleManagement";

export default function ResearchSchedulingPage() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <WhitePanel>
            <ResearchScheduleManagement />
          </WhitePanel>
        </Grid>
        <Grid item xs={12} md={6}>
          <WhitePanel>
            <LabDeviceManagement />
          </WhitePanel>
        </Grid>
      </Grid>
    </>
  );
}
